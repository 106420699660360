import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ThemeSwitch } from "./theme.switch";

export const Footer: React.FC = () => {
  return (
    <footer
      id="contact"
      className="sticky bottom-0 z-10 bg-gray-200 dark:bg-black"
    >
      <div className="flex justify-around">
        <ul className="flex justify-center space-x-6">
          <li>
            <a
              href="https://twitter.com/vlkngulen"
              target="_blank"
              rel="noopener noreferrer"
              className="monochrome-icon"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/volkanguelen"
              target="_blank"
              rel="noopener noreferrer"
              className="monochrome-icon"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/volkangulen"
              target="_blank"
              rel="noopener noreferrer"
              className="monochrome-icon"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </li>
          <li>
            <a
              href="mailto:me@volkangulen.com"
              target="_blank"
              rel="noopener noreferrer"
              className="monochrome-icon"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
        </ul>
      </div>
      <ThemeSwitch />
    </footer>
  );
};
