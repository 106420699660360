import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigation from "@components/navigation";
import { Home } from "@components/home";
import { CV } from "@components/cv";
import { Footer } from "@components/footer";

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-row justify-around w-screen">
        <div className="flex flex-col w-2/3 min-h-screen align-middle justify-between">
          <Navigation />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cv" element={<CV />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
