import { Panel } from "@components/panel";

export const CV: React.FC = () => {
  return (
    <Panel
      title="About Me"
      source="me"
      hasSocialMedia={false}
      summary=""
      metadata={{
        date: "",
        category: "",
        avgReadingTime: "",
      }}
    />
  );
};
