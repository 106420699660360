import { faLightbulb, faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DARK, LIGHT, THEME } from "@utils/constants";
import React, { useEffect } from "react";

export const ThemeSwitch: React.FC = () => {
  const darkModeEnabled = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;
  const theme = localStorage.getItem(THEME) ?? (darkModeEnabled ? DARK : LIGHT);
  const [currentMode, setCurrentMode] = React.useState(theme);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      if (currentMode === LIGHT) {
        html.classList.remove(DARK);
      } else {
        html.classList.add(DARK);
      }
    }
  }, [currentMode]);

  const toggleTheme = () => {
    if (currentMode === LIGHT) {
      localStorage.setItem(THEME, DARK);
      setCurrentMode(DARK);
    } else {
      localStorage.setItem(THEME, LIGHT);
      setCurrentMode(LIGHT);
    }
  };

  return (
    <FontAwesomeIcon
      className="absolute right-0 bottom-0 p-2 monochrome-icon"
      icon={currentMode === DARK ? faLightbulb : faMoon}
      onClick={toggleTheme}
    />
  );
};
